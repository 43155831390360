<template>
    <section>
        <h1 class="section--tile mb-5">Gallery</h1>
        <div class="images-container">
            <figure v-for="imageSrc in images" :key="imageSrc">
                <img :src="imageSrc" alt="Image" />
            </figure>
        </div>
    </section>
</template>

<script>
import { db } from '../firebase';
export default {
    data() {
        return {
            images: undefined
        }

    },
    created() {
        this.fetchImages()
    },
    methods: {
        async fetchImages() {
            try {
                const imageRef = db.storage().ref('gallery');
                const imageList = await imageRef.listAll();

                const promises = imageList.items.map(async (item) => {
                    const url = await item.getDownloadURL();
                    return url;
                });

                this.images = await Promise.all(promises);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.section--tile {
    font-weight: bolder;
    font-size: 1.5rem;
    color: white;
    font-family: 'Inter', sans-serif;
    padding: 10px 15px;
    background-color: #446DF6;
    width: fit-content;
}
img {
    max-width: 100%;
    display: block;
    border-radius: 10px;
}

figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
}

figure>img {
    grid-row: 1 / -1;
    grid-column: 1;
}

.images-container {
    column-count: 6;
    column-gap: 10px;
    min-height: 55vh;
}

@media (max-width: 1408px ) {
    .images-container {
        column-count: 4;
    }
}

@media (max-width: 1215px) {
    .images-container {
        column-count: 2;
    }
}

@media (max-width: 768px) {
    .images-container {
        column-count: 1;
    }
}</style>